import { SocialNavBar } from '../socialNavBar/SocialNavBar'
import logo from '../../logo.svg'
import phoneIcon from '../../assets/icons/contact_phone_icon.svg'
import mailIcon from '../../assets/icons/contact_mail_icon.svg'
import mapIcon from '../../assets/icons/contact_map_icon.svg'
import styles from './footerMob.module.css'

export const FooterMob = () => {
    return (
        <div className={styles.container}>
            <div className={styles.socialBlock}>
                <img className={styles.logo} src={logo} alt="logo"/>
                <SocialNavBar style={{position:'relative', right:0}} />
            </div>

            <div className={styles.wrapper}>
                <div className={styles.navBlock}>
                    <a className={styles.link} href="#about">ABOUT</a>
                    <a className={styles.link} href="#roles">ROLES</a>
                    <a className={styles.link} href="#blog">BLOGS</a>
                    <a className={styles.link} href="#contacts">CONTACTS</a>
                </div>
                <div className={styles.navBlock}>
                    <a className={styles.link} href="/">Terms Of Use</a>
                    <a className={styles.link} href="/">Privacy Policy</a>
                    <a className={styles.link} href="/">Cookie Policy</a>
                </div>
                <div className={styles.navBlock}>
                    <a className={styles.link} href="tel:+37599876543">
                        <img src={phoneIcon} alt="phone"/>
                        + (375) 99 876543
                    </a>
                    <a className={styles.link} style={{textTransform:"lowercase"}} href="mailto:minos@mail.com">
                        <img src={mailIcon} alt="mail"/>
                        minos@mail.com
                    </a>
                    <a
                        className={styles.link}
                        target="_blank"
                        rel="noreferrer"
                        href="https://maps.app.goo.gl/VL9vCDpVCDcYEdmP7"
                        style={{textTransform:"none"}}
                    >
                        <img src={mapIcon} alt="map"/>
                        Cyprus: 79 athinon Str.,
                        <br/>Nora Court, 4th Floor, door no. 404,
                        <br/>3040, Limassol
                    </a>
                </div>
            </div>
        </div>
    )
}