import iconLinkedIn from '../../assets/icons/soc_linkedIn_icon.svg'
import iconTelegram from '../../assets/icons/soc_telegram_icon.svg'
import iconWhatsApp from '../../assets/icons/soc_watsApp_icon.svg'
import styles from './socialNavBar.module.css'

export const SocialNavBar = ({style}) => {
    return (
        <ul className={styles.container} style={style}>
            <li>
                <a href="https://www.linkedin.com/in/minos-pitsillides-a193b618a/" target="_blank" rel="noreferrer">
                    <img src={iconLinkedIn} alt="icon LinkedIn"/>
                </a>
            </li>
            <li>
                <a href="https://t.me/minospi" target="_blank" rel="noreferrer">
                    <img src={iconTelegram} alt="icon Telegram"/>
                </a>
            </li>
            <li>
                <a href="https://wa.me/35796357588" target="_blank" rel="noreferrer">
                    <img src={iconWhatsApp} alt="icon WhatsApp"/>
                </a>
            </li>
        </ul>
    )
}