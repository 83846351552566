const blogData = [
    {
        picture: 'blogPoster',
        theme: 'Marketing',
        time: '5 min read',
        topic: 'Boost Your Business Level',
        description: 'Learn effective strategies to engage your audience.'
    },
    {
        picture: 'blogPoster1',
        theme: 'Marketing',
        time: '5 min read',
        topic: 'Boost Your Business Level',
        description: 'Learn effective strategies to engage your audience.'
    },
    {
        picture: 'blogPoster2',
        theme: 'Marketing',
        time: '5 min read',
        topic: 'Boost Your Business Level',
        description: 'Learn effective strategies to engage your audience.'
    },
]

export default blogData