import {isBrowser, isMobileOnly} from 'react-device-detect'
import laptopIcon from '../../assets/icons/roles_laptop.svg'
import moleculeIcon from '../../assets/icons/roles_molecule.svg'
import peopleIcon from '../../assets/icons/roles_people.svg'
import loupeIcon from '../../assets/icons/roles_loupe.svg'
import styles from './sectionRoles.module.css'

export const SectionRoles = () => {
    return (
        <div id="roles" className={styles.container}>
            <div className={styles.wrapper}>
                <h2 className={`${styles.title} ${isMobileOnly && styles.titleMob}`}>My Roles</h2>

                <div className={`${styles.mainContent} ${!isBrowser && styles.mainContentMob}`}>
                    <div className={`${styles.col} ${!isBrowser && styles.colMob}`}>
                        <div className={styles.item}>
                            <img className={styles.icon} src={laptopIcon} alt="laptop icon"/>
                            <p className={styles.text}>
                                My experience and passion for computing lead me to launch my first company in 2013, an
                                organisation that provides reliable, seamless  IT support to businesses.
                            </p>
                            <p>
                                A decade later, the company continues to forge a relentless path into the future of IT
                                solutions, providing our clients with the support they need to ensure minimal downtime and
                                grow their business.
                            </p>
                        </div>

                        <div className={styles.item}>
                            <img className={styles.icon} src={moleculeIcon} alt="laptop icon"/>
                            <p className={styles.text}>
                                In an increasingly digital world, my focus and energy has greatly shifted into the world
                                of cybersecurity.
                            </p>
                            <p>
                                The rise of crypto and AI advancements makes this a key component that all businesses need
                                to incorporate into their strategies. I am dedicated to staying at the forefront of the
                                latest trends and developments, ensuring rock-solid cybersecurity for my clients.
                            </p>
                        </div>
                    </div>

                    <div className={`${styles.col} ${!isBrowser && styles.colMob} ${isBrowser ? styles.shifted : styles.shiftedMob}`}>
                    <div className={styles.item}>
                        <img className={styles.icon} src={peopleIcon} alt="laptop icon"/>
                        <p>
                            Building upon my success in the IT world and recognising an unmet need of the clients I
                            worked with, my next venture was into the world of web and software development and
                            digital marketing.
                        </p>
                        <p className={styles.text}>
                            I built a team of other likeminded creators and innovators who make the digital
                            aspirations and dreams of our clients a reality.
                        </p>
                    </div>

                    <div className={styles.item}>
                        <img className={styles.icon} src={loupeIcon} alt="laptop icon"/>
                        <p className={styles.text}>
                            Recruitment is an industry that is certainly not being left behind in technological
                            advancement.
                        </p>
                        <p>
                            AI-fuelled assessments and candidate screening has revolutionised hiring processes; my
                            knowledge and expertise of the latest technology enables my agency to quickly and
                            efficiently identify the best candidates for our clients’ vacant roles in the
                            technological sector.
                        </p>
                    </div>
                </div>
                </div>
            </div>
        </div>
    )
}