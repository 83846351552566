import bg from '../../assets/img/contacts_bg.jpg'
import styles from './sectionContacts.module.css'
import {isMobileOnly} from "react-device-detect";

export const SectionContacts = () => {
    return (
        <div id="contacts" className={styles.container}>
            <img className={styles.bg} src={bg} alt=""/>
            <div className={`${styles.wrapper} ${isMobileOnly && styles.wrapperMob}`}>
                <h2 className={`${styles.title} ${isMobileOnly && styles.titleMob}`}>Let's talk tech!</h2>
                <p className={styles.text}>
                    Whether you're looking to revolutionise your IT infrastructure or explore new horizons in digital
                    strategy, I'm here to help. Get in touch and let's make technology work for you.
                </p>
                <div className={`${styles.btnsWrapper} ${isMobileOnly && styles.btnsWrapperMob}`}>
                    <button className={`${styles.btnIron} ${isMobileOnly && styles.btnMob}`}>
                        schedule a call
                    </button>
                    <button className={`${styles.btnTransparent} ${isMobileOnly && styles.btnMob}`}>
                        send a message
                    </button>
                </div>
            </div>
        </div>
    )
}