const testimonialsData = [
    {
        photo: 'immanuelParkerPhoto',
        text: 'I love bringing ideas to life through crafted visuals and meaningful animations, playing with colors, ' +
            'composition, typography and  crafted visuals and meaningful details project.',
        company: 'VP of Lognes Design',
        author: 'Immanuel Parker'
    },
    {
        photo: 'dmitryPayetPhoto',
        text: 'We believe that every photo has a story to tell, and it\'s our mission to help you tell yours. We ' +
            'understand that every project is unique, and we work closely ',
        company: 'Global Chart Co.',
        author: 'Dmitry Payet'
    },
    {
        photo: 'zainurWahidPhoto',
        text: ' Whether you\'re looking for family portraits, commercial photography, or event coverage, we are ' +
            'committed to capturing your vision and bringing your ideas to life family portraits.',
        company: 'Skyper Logistic',
        author: 'Zainur Wahid'
    },
    {
        photo: 'alexaSarahPhoto',
        text: 'I love bringing ideas to life through crafted visuals and meaningful animations, playing with ' +
            'colors, composition, typography and details that exceed their expectations.',
        company: 'Ship & Go',
        author: 'Alexa Sarah'
    },
    {
        photo: 'immanuelParkerPhoto',
        text: 'I love bringing ideas to life through crafted visuals and meaningful animations, playing with colors, ' +
            'composition, typography and  crafted visuals and meaningful details project.',
        company: 'VP of Lognes Design',
        author: 'Immanuel Parker'
    },
    {
        photo: 'dmitryPayetPhoto',
        text: 'We believe that every photo has a story to tell, and it\'s our mission to help you tell yours. We ' +
            'understand that every project is unique, and we work closely ',
        company: 'Global Chart Co.',
        author: 'Dmitry Payet'
    },
    {
        photo: 'zainurWahidPhoto',
        text: ' Whether you\'re looking for family portraits, commercial photography, or event coverage, we are ' +
            'committed to capturing your vision and bringing your ideas to life family portraits.',
        company: 'Skyper Logistic',
        author: 'Zainur Wahid'
    },
    {
        photo: 'alexaSarahPhoto',
        text: 'I love bringing ideas to life through crafted visuals and meaningful animations, playing with ' +
            'colors, composition, typography and details that exceed their expectations.',
        company: 'Ship & Go',
        author: 'Alexa Sarah'
    },
]

export default testimonialsData