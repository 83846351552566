import { Swiper, SwiperSlide } from 'swiper/react'
import {isBrowser, isMobileOnly} from 'react-device-detect'
import { Testimonial } from '../testimonial/Testimonial'
import { Navigation } from 'swiper/modules'
import testimonialsData from '../../data/testimonials.data'
import bg from "../../assets/img/testimonials_bg.jpg"
import iconArrowWhite from '../../assets/icons/arrow_left_white.svg'
import 'swiper/swiper-bundle.css'
import styles from './sectionTestimonials.module.css'

export const SectionTestimonials = () => {
    return (
        <div id="testimonials" className={styles.container}>
            <img className={styles.bg} src={bg} alt=""/>
            <div className={`${styles.wrapper} ${isMobileOnly && styles.wrapperMob}`}>
                <h2 className={`${styles.title} ${isMobileOnly && styles.titleMob}`}>Testimonials</h2>

                <Swiper
                    modules={[Navigation]}
                    spaceBetween={15}
                    slidesPerView={isBrowser ? 4 : isMobileOnly ? 1 : 2}
                    navigation={{
                        prevEl: `.${styles.customprevious}`,
                        nextEl: `.${styles.customnext}`,
                    }}
                >
                    {testimonialsData.map((testimonial, index) => (
                        <SwiperSlide key={index}>
                            <Testimonial testimonial={testimonial}/>
                        </SwiperSlide>
                    ))}
                </Swiper>

                <div className={styles.navBtnsWrapper}>
                    <div className={styles.customprevious}>
                        <img src={iconArrowWhite} alt="arrow left"/>
                    </div>
                    <div className={styles.customnext}>
                        <img src={iconArrowWhite} alt="arrow right"/>
                    </div>
                </div>

            </div>
        </div>
    )
}