import {isBrowser, isMobileOnly} from 'react-device-detect'
import styles from './sectionAbout.module.css'

export const SectionAbout = () => {
    return (
        <div id="about" className={styles.container}>
            <div className={`${styles.wrapper} ${isMobileOnly && styles.wrapeerMob}`}>

                <p className={`${styles.text} ${!isBrowser && styles.textMob}`}>
                    Welcome! My name is Minos, and I am passionate about all things tech. From my early days of
                    exploring gadgets to spearheading pioneering projects in the IT sector, my journey has been
                    characterised by an unwavering dedication to innovation and progress.
                </p>
                <p className={`${styles.text} ${styles.textDark} ${!isBrowser && styles.textMob}`}>
                    Since 2009, I've pursued excellence in technology, graduating with a BSc in Computing from the
                    University of East London. My journey through esteemed institutions like top law firms and
                    international banks honed my expertise in IT consulting, infrastructure management, and digital
                    strategy. Certified by Cisco and Microsoft, I'm committed to technical excellence, mastering
                    networks and deploying security solutions.
                </p>
                <p className={`${styles.text} ${!isBrowser && styles.textMob}`}>
                    For the last decade, I have been an entrepreneur in the technological sector, founding companies
                    that are dedicated to innovative solutions within IT, Digital Marketing, Cybersecurity, and
                    Recruitment.
                </p>
                <p className={`${styles.text} ${!isBrowser && styles.textMob}`}>
                    Today, I find myself at the intersection of technology and innovation, ready to tackle new
                    challenges. Whether it's developing innovative solutions or exploring cybersecurity, I'm prepared.
                </p>
                <p className={`${styles.text} ${!isBrowser && styles.textMob}`}>
                    Let's connect and explore the endless possibilities that technology offers.
                </p>

                <button className={`${styles.btn} ${isMobileOnly && styles.btnMob}`}>get in touch</button>

                <div className={isMobileOnly ? styles.signMob : styles.sign}>
                    <p className={styles.signTitle}>Entrepreneur</p>
                    <p className={styles.name}>Minos Pitsillides</p>
                </div>
            </div>
        </div>
    )
}