import immanuelParkerPhoto from '../../assets/img/testimonial_photo.jpg'
import dmitryPayetPhoto from '../../assets/img/testimonial_photo.jpg'
import zainurWahidPhoto from '../../assets/img/testimonial_photo.jpg'
import alexaSarahPhoto from '../../assets/img/testimonial_photo.jpg'
import styles from './testimonial.module.css'

export const Testimonial = ({ testimonial }) => {
    const photoAddress = () => {
        if (testimonial.photo === "immanuelParkerPhoto") {
            return immanuelParkerPhoto
        } else if (testimonial.photo === "dmitryPayetPhoto") {
            return dmitryPayetPhoto
        } else if (testimonial.photo === "zainurWahidPhoto") {
            return zainurWahidPhoto
        } else if (testimonial.photo === "alexaSarahPhoto") {
            return alexaSarahPhoto
        }
    }

    return (
        <div className={styles.container}>
            <div className={styles.mainContent}>
                <img className={styles.photo} src={photoAddress()} alt="blog"/>
                <p className={styles.text}>
                    {testimonial.text}
                </p>
            </div>

            <div className={styles.authorWrapper}>
                <p className={styles.company}>{testimonial.company}</p>
                <p className={styles.author}>{testimonial.author}</p>
            </div>
        </div>
    )
}