import {isMobileOnly} from 'react-device-detect'
import {BlogCart} from '../blogCart/BlogCart'
import blogData from "../../data/blog.data";
import bg from '../../assets/img/blog_bg.jpg'
import styles from './sectionBlog.module.css'

export const SectionBlog = () => {
    return (
        <div id="blog" className={styles.container}>
            <img className={styles.bg} src={bg} alt=""/>
            <div className={`${styles.wrapper} ${isMobileOnly && styles.wrapperMob}`}>
                <h2 className={`${styles.title} ${isMobileOnly && styles.titleMob}`}>My blog</h2>

                <div className={`${styles.blogList} ${isMobileOnly && styles.blogListMob}`}>
                    {blogData.map((blog, index) => (
                        <BlogCart key={index} blog={blog} />
                    ))}
                </div>
            </div>
        </div>
    )
}