import blogPoster from '../../assets/img/blog_poster.jpg'
import styles from './blogCart.module.css'

export const BlogCart = props => {
    const {
        picture,
        theme,
        time,
        topic,
        description
    } = props.blog

    const photoAddress = () => {
        if (picture === "blogPoster") {
            return blogPoster
        } else if (picture === "blogPoster1") {
            return blogPoster
        } else if (picture === "blogPoster2") {
            return blogPoster
        }
    }

    return (
        <div className={styles.container}>
            <img className={styles.poster} src={photoAddress()} alt="blog poster"/>
            <div className={styles.content}>
                <div className={styles.blogInfo}>
                    <div className={styles.topic}>{theme}</div>
                    <div className={styles.time}>{time}</div>
                </div>
                <div className={styles.textWrapper}>
                    <h3 className={styles.blogTitle}>
                        {topic}
                    </h3>
                    <p className={styles.blogText}>
                        {description}
                    </p>
                </div>
            </div>
        </div>
    )
}